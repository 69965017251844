<template>
    <div>

      <v-snackbar v-model="showSnackBar" color="primary" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: #ffffff">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: #ffffff">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>

        <v-card rounded="lg">
            <v-card-title>
                <span class="editbox">ADD SUB ADMIN</span>
                <v-spacer></v-spacer>
                <v-btn color="red" icon @click="closeDialog()">
                  <v-icon>mdi-close</v-icon>
                </v-btn>


            </v-card-title>

            <v-card-text>
                <v-form>
                    <v-layout wrap justify-center px-2>
                      <v-flex xs12>
                        <span class="label">Name</span>
                        <v-text-field
                          class="pt-2 text-des"
                          style="font-size:14px"
                          v-model="name"
                          outlined
                          dense
                          :rules="nameRules"
                           type="text"
                        ></v-text-field>
                      </v-flex>
                  
                      <v-flex xs6 pr-2 >
                        <span class="label">Phone Number</span>
                        <v-text-field
                          class="pt-2 text-des"
                          style="font-size:14px"
                          v-model="phoneNumber"
                          outlined
                          dense
                          :rules="phoneRules"
                           type="number"
                        ></v-text-field>
                      </v-flex>
                  
                      <v-flex xs6 >
                        <span class="label">Email</span>
                        <v-text-field
                          class="pt-2 text-des"
                          style="font-size:14px"
                          v-model="email"
                          outlined
                          dense
                          :rules="emailRules"
                           type="email"
                        ></v-text-field>
                      </v-flex>
                  
                      <v-flex xs6 pr-2 >
                        <span class="label">Password</span>
                        <v-text-field
                          class="pt-2 text-des"
                          style="font-size:14px"
                          v-model="password"
                          outlined
                          dense
                          :rules="passwordRules"
                          type="text"
                        ></v-text-field>
                      </v-flex>
                  
                      <v-flex xs6 >
                        <span class="label">Confirm Password</span>
                        <v-text-field
                          class="pt-2 text-des"
                          style="font-size:14px"
                          v-model="confirmPassword"
                          outlined
                          dense
                          :rules="confirmPasswordRules"
                          type="text"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-form>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="py-4 justify-end headline grey lighten-2">
                <v-btn outlined color="grey" text @click="closeDialog()"><span style="color: black;">Cancel</span>
                </v-btn>
                <v-btn color="primary"  @click="validateInput">Save</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
//import { VueEditor } from "vue2-editor";
// import ImageComp from "@/components/Common/singleImages";
import axios from "axios";
export default {
    components: {
        //ImageComp,
        // VueEditor,
    },
    data() {
        return {
  showSnackBar:false,
      timeout:5000,
      msg: "",


            dialog: false,
            // No initial date
            name: null,
            email: null,
            password:null,
            confirmPassword:null,
            phoneNumber:null,

            nameRules: [
  (v) => !!v || "Name is required", // Required field
  
  (v) => (v && v.length <= 50) || "Name must be less than 50 characters", // Maximum length
  (v) => /^[a-zA-Z\s]+$/.test(v) || "Name must contain only letters and spaces", // Only letters and spaces allowed
  (v) => /^[A-Z][a-zA-Z]*([\s][A-Z][a-zA-Z]*)*$/.test(v) || "Each word must start with a capital letter", // Capitalized words
],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        // roleRules: [(v) => !!v || "Role is required"],
        phoneRules: [
          (v) => !!v || "Phone number is required",
          (v) => (v && v.length <= 10) || "Phone Number must be 10 digts",
          (v) => (v && v.length >= 10) || "Phone Number must be 10 digts",
          (v) => /^\d{10}$/.test(v) || "Phone number must be a number",
        ],
        passwordRules: [
      (v) => !!v || "Password is required",
      (v) => v.length >= 8 || "Password must be at least 8 characters",
      (v) => /[A-Z]/.test(v) || "Password must contain at least one uppercase letter",
      (v) => /[a-z]/.test(v) || "Password must contain at least one lowercase letter",
      (v) => /[0-9]/.test(v) || "Password must contain at least one number",
      (v) => /[@$!%*?&#]/.test(v) || "Password must contain at least one special character",
    ],
    confirmPasswordRules: [
      (v) => !!v || "Confirm password is required",
      (v) => v === this.password || "Passwords do not match",
    ],

        };
    },

    //   created() {
    //     this.getDept();
    //     // this.getData(); // Fetch departments when component is created
    //   },
    //   mounted() {
    //     this.getDept();
    //     //this.getCourse();
    //     //this.getCat();
    //     //this.getInst();
    //     this.getData();

    //   },
    methods: {
        closeDialog() {
            this.$emit("stepper", {
                ref: "addSubAdmin",
                dialog: false,
            });
        },

        // saveDialog() {
        //     this.$emit("stepper", {
        //         ref: "addSubAdmin",
        //         dialog: false,
                
        //     });
        // },
        saveDialog(msg) {
    this.$emit("stepper", {
        ref: "addSubAdmin",
        dialog: false,
        msg: msg, // Pass the response message
    });
},
        updateDate(feastDay) {
      this.feastDay = feastDay;
      this.dialog = false; // Close the dialog after selecting the date
    },

    validateInput() {
    if (!this.name) {
      this.msg = "Please Provide Name";
      this.showSnackBar = true;
      return;
    } 
    else if (!this.phoneNumber) {
      this.msg = "Please Provide Phone Number";
      this.showSnackBar = true;
      return;
    } 
    else if (!this.email) {
      this.msg = "Please Provide Email";
      this.showSnackBar = true;
      return;
    } else if (!this.password) {
      this.msg = "Please Provide Password";
      this.showSnackBar = true;
      return;
    } else if (!this.confirmPassword) {
      this.msg = "Please Provide Confirm Password";
      this.showSnackBar = true;
      return;
    } else if (this.password !== this.confirmPassword) {
      this.msg = "Password and Confirm Password do not match";
      this.showSnackBar = true;
      return;
    }
     else if (!this.validatePassword(this.password)) {
      this.msg = "Password must be at least 8 characters, contain at least one uppercase letter, one lowercase letter, one number, and one special character.";
      this.showSnackBar = true;
      return;
    }
     else {
      this.itemadd();
    }
  },

  validatePassword(password) {
    // Regular expression to check the password criteria
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
    return passwordRegex.test(password);
  },

    itemadd() {
    var data = {};
    data["name"] = this.name;
    data["phoneNumber"] = this.phoneNumber;
    data["email"] = this.email;
    data["password"] = this.password;
    data["confirmPassword"] = this.confirmPassword;
    
    axios({
        url: "/subadmin/add",
        method: "POST",
        data: data,
        headers: {
            token: localStorage.getItem("token"),
        },
    })
    .then((response) => {
        this.appLoading = false;
        if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.dialog = false;
            this.name = null;
            this.phoneNumber = null;
            this.password = null; 
            this.confirmPassword = null;
            this.email = null;

            this.saveDialog(this.msg); // Pass the message here
        } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
        }
    })
    .catch((err) => {
        this.appLoading = false;
        this.ServerError = true;
        console.log(err);
    });
}

      



    },
    
  
};
</script>